<template>
  <v-dialog
    v-model="value"
    v-if="value"
    @click:outside="close"
    scrollable
    max-width="800"
  >
    <v-card>
      <v-card-title>
        {{$t('createNewCategory')}}
      </v-card-title>
      <v-card-subtitle>
        {{$t('createNewCategory')}}
      </v-card-subtitle>
      <v-card-text>
        <CategoryForm v-model="category" />
      </v-card-text>
      <v-card-actions class="pb-4">
        <div style="width: 100%;">
          <v-alert
            v-if="couldNotSaveChanges"
            type="error"
            v-model="couldNotSaveChanges"
            dismissible
          >
            {{$t('sorryCouldNotCreateCategory')}}
          </v-alert>
          <v-btn
            block
            color="success"
            large
            :disabled="processing || !requiredValuesFilled"
            :loading="processing"
            @click="createCategory"
          >
            {{$t('create')}}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CategoryForm from "@/components/CategoryForm"

export default {
	name: "CreateCategoryDialog",
	components: {
		CategoryForm
	},
	props: {
		value: {
      type: Boolean
    },
		category: {
      type: Object, 
      default: () => {}
    }
	},
	data() {
		return {
			processing: false,
      couldNotSaveChanges: false
		}
	},
  computed: {
    requiredValuesFilled() {
      if(this.category.name) {
        return true 
      }
      return false
    }
  },  
	methods: {
		close() {
			this.$emit('close')
		},
		createCategory() {
			this.processing = true
			this.$store.dispatch('createCategory', this.category).then(result => {
        // this.$emit('created', this.category)
        if(result) {
          this.$emit('created', this.category)
        } else {
          this.couldNotSaveChanges = true
        }
      }).catch(() => {
        this.couldNotSaveChanges = true
      }).finally(() => {
        this.close()
        this.processing = false
      })
		}
	}
}
</script>

<style scoped>

</style>